import {
    initializeRealUserMonitoring,
    setUser,
} from '@eventbrite/real-user-monitoring';
import { App, User } from './types';

// This sample rate was extracted from GA usage data.
const SAMPLE_RATE = 0.71;

export const initDatadogRUM = ({ app, user }: { app: App; user: User }) => {
    initializeRealUserMonitoring({
        sampleRate: SAMPLE_RATE,
        service: app.name,
        version: app.version,
    });

    setUser({
        id: user.publicId,
        name: user.fullName,
        email: user.email,
    });
};
