import { gettext } from '@eventbrite/i18n';
import React from 'react';

export interface Props {
    logo?: {
        url: string;
    };
    thumbnails?: { width: string; url: string }[];
}

export const EventLogo: React.FC<Props> = ({ logo, thumbnails = [] }) => {
    if (!thumbnails.length && (!logo || !logo.url)) return null;

    const thumbnailSizes = thumbnails.map(
        ({ width, url }) => `${url} ${width}w`,
    );

    return (
        <div className="dc-event-card__image">
            <img
                src={logo?.url}
                srcSet={
                    thumbnailSizes.length
                        ? thumbnailSizes.join(', ')
                        : undefined
                }
                sizes={thumbnailSizes.length ? '720px' : undefined}
                alt={gettext('Event image').toString()}
            />
        </div>
    );
};
