import isEqual from 'lodash/isEqual';
import momentTimezone from 'moment-timezone';
import {
    BrowserTypes,
    DIGITAL_CONTENT_EMBED_PARAM,
    EVENT_TYPE,
} from './constants';

// Takes a modules and type name and look to see if there are any
// Returns true or false
export const hasModuleOfType = (
    modules: Array<{ type: string }> = [],
    type: string,
) => modules.filter((module) => module.type === type).length > 0;

export const isEmbedUrl = (url: string) =>
    url.includes(`${DIGITAL_CONTENT_EMBED_PARAM}=true`);

export const isVimeoShowcaseUrl = (url: string) =>
    url.includes('vimeo') && url.includes('showcase');

export const isVimeoShowcaseEmbedUrl = (url: string) =>
    isVimeoShowcaseUrl(url) && isEmbedUrl(url);

export const getDateTime = (locale: string, dateTime?: string) => {
    momentTimezone.locale(locale);
    const localTimezone = momentTimezone.tz.guess(true);
    const dateTimeWithTimezone = dateTime
        ? momentTimezone.utc(dateTime)
        : momentTimezone.utc();

    return dateTimeWithTimezone.tz(localTimezone);
};

export const getExtraData = ({
    event,
    ticketClasses = [],
}: {
    event: { start: { utc: string }; end: { utc: string }; isSeries: boolean };
    ticketClasses: Array<any>;
}) => {
    const extras = {
        ticket_classes: ticketClasses.length ? ticketClasses.join() : undefined,
        start_date_utc: event.isSeries ? event.start.utc : undefined,
        end_date_utc: event.isSeries ? event.end.utc : undefined,
    };

    // If this is a repeating event then send extra params
    if (event.isSeries) {
    }

    return extras;
};

export const isWebinar = (eventType: string) =>
    eventType === EVENT_TYPE.ZOOM_EVENT_TYPE_WEBINAR;

const vimeoApiUrl = (url: string) =>
    `https://vimeo.com/api/oembed.json?url=${url}`;

const fetchVimeoData = (url: string) =>
    fetch(vimeoApiUrl(url), {
        method: 'GET',
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                return null;
            }
        })
        .catch(() => null);

export const getVimeoEmbedUrl = (url: string) =>
    fetchVimeoData(url).then((response) => {
        if (!response) return null;

        const { html, video_id } = response;

        if (video_id) return `https://player.vimeo.com/video/${video_id}`;
        if (html) return html.split('src="')[1].split(/[" >]/)[0];
    });

export const isNone = (value: any) =>
    value === undefined ||
    value === null ||
    String(value).toLowerCase() === 'none';

export const isZoomEmbedAvailable = () => {
    const browser = getUserBrowser();
    return !isEqual(browser, BrowserTypes.internetExplorer);
};

export const getUserBrowser = () => {
    const agent = navigator.userAgent;
    let browser;

    if (agent.indexOf('Firefox') > -1) {
        browser = BrowserTypes.firefox;
    } else if (agent.indexOf('SamsungBrowser') > -1) {
        browser = BrowserTypes.samsungInternet;
    } else if (agent.indexOf('Opera') > -1 || agent.indexOf('OPR') > -1) {
        browser = BrowserTypes.opera;
    } else if (agent.indexOf('Trident') > -1) {
        browser = BrowserTypes.internetExplorer;
    } else if (agent.indexOf('Edge') > -1) {
        browser = BrowserTypes.edge;
    } else if (agent.indexOf('Chrome') > -1) {
        browser = BrowserTypes.chrome;
    } else if (agent.indexOf('Safari') > -1) {
        browser = BrowserTypes.safari;
    } else {
        browser = BrowserTypes.unknown;
    }

    return browser;
};
