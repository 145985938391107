import React, { PureComponent } from 'react';
import { css, cx } from 'emotion';
import PropTypes from 'prop-types';
import { gettext } from '@eventbrite/i18n';

import * as analytics from '@eventbrite/site-analytics';
import { deepKeysToCamel } from '@eventbrite/transformation-utils';
import { sdkRequest } from '@eventbrite/http';
import { Button } from '@eventbrite/eds-button';

import { Icon } from '@eventbrite/eds-icon';
import { GlobeChunky } from '@eventbrite/eds-iconography';
import { LogoFacebook } from '@eventbrite/eds-iconography';
import { LogoTwitter } from '@eventbrite/eds-iconography';

import {
    GA_ACTION_FOLLOW_ORGANIZER,
    GA_ACTION_UNFOLLOW_ORGANIZER,
    GA_DEFAULT_CATEGORY,
} from '../constants';

import {
    HEAP_FOLLOW_ORGANIZER,
    HEAP_UNFOLLOW_ORGANIZER,
    HEAP_FOLLOW_ORGANIZER_SUCCESSFUL,
    HEAP_UNFOLLOW_ORGANIZER_SUCCESSFUL,
    HEAP_PAGE_AREA,
} from './constants';

import { track } from '@eventbrite/datalayer-library';

export const getOrganizerUrl = (organizerId) =>
    `/api/v3/destination/organizers/${organizerId}/?expand.destination_profile=image`;
export const followOrganizerUrl = (organizerId) =>
    `/api/v3/destination/organizers/${organizerId}/follow/`;
export const unfollowOrganizerUrl = (organizerId) =>
    `/api/v3/destination/organizers/${organizerId}/unfollow/`;

export const OrganizerLogo = ({ image, url } = {}) => {
    if (!image) {
        return null;
    }
    const logoClassName = css`
        background-image: url(${image.url});
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        display: block;
        height: 50px;
        width: 50px;
    `;

    // img is hidden bc it's only for SEO purposes
    const logoImgClassName = css`
        display: none;
    `;

    return (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={logoClassName}
        >
            <img
                className={logoImgClassName}
                src={image.url}
                alt={gettext('Organizer logo')}
            />
        </a>
    );
};

const OrganizerInfo = ({ name, url } = {}) =>
    (name && (
        <div className="eds-l-mar-top-4">
            <p className="eds-text-bm eds-text-color--ui-600">
                {gettext('Organized by')}
            </p>
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="eds-text-weight--heavy eds-text-color--ui-800"
            >
                {name}
            </a>
        </div>
    )) ||
    null;

const OrganizerSocial = ({ facebook, twitter, websiteUrl } = {}) => (
    <div className="eds-l-mar-top-3">
        {twitter && (
            <a
                href={`https://twitter.com/${twitter}`}
                target="_blank"
                rel="noopener noreferrer"
                className="eds-l-mar-right-2"
            >
                <Icon
                    size="small"
                    color="grey-700"
                    title={gettext('Twitter')}
                    type={<LogoTwitter />}
                />
            </a>
        )}
        {facebook && (
            <a
                href={`https://facebook.com/${facebook}`}
                target="_blank"
                rel="noopener noreferrer"
                className="eds-l-mar-right-2"
            >
                <Icon
                    size="small"
                    color="grey-700"
                    title={gettext('Facebook')}
                    type={<LogoFacebook />}
                />
            </a>
        )}
        {websiteUrl && (
            <a
                href={websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="eds-l-mar-right-2"
            >
                <Icon
                    size="small"
                    color="grey-700"
                    title={gettext('Website')}
                    type={<GlobeChunky />}
                />
            </a>
        )}
    </div>
);

export default class Organizer extends PureComponent {
    static propTypes = {
        onContactOrganizerClicked: PropTypes.func,
        organizerId: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const { organizerId } = this.props;
        // fetch organizer
        sdkRequest(getOrganizerUrl(organizerId))
            .then((organizer) => {
                const transformedOrganizer = deepKeysToCamel(organizer);

                this.setState({
                    followedByYou: transformedOrganizer.followedByYou,
                    organizer: transformedOrganizer,
                });
            })
            .catch((error) => {
                this.setState({ error });
            });
    }

    handleClickFollow = () => {
        const { organizerId } = this.props;
        const { followedByYou } = this.state;

        const followUrl = followedByYou
            ? unfollowOrganizerUrl(organizerId)
            : followOrganizerUrl(organizerId);

        // give user immediate feedback
        this.setState({
            followedByYou: !followedByYou,
        });

        // send GA event
        analytics.trackAnalyticsEvent({
            category: GA_DEFAULT_CATEGORY,
            action: followedByYou
                ? GA_ACTION_UNFOLLOW_ORGANIZER
                : GA_ACTION_FOLLOW_ORGANIZER,
            label: organizerId,
        });

        // (un/)follow organizer
        sdkRequest(followUrl, { method: 'POST' })
            .then((response) => {
                const transformedResponse = deepKeysToCamel(response);

                this.setState({
                    followedByYou: transformedResponse.followedByYou,
                });

                track({
                    eventName: followedByYou
                        ? HEAP_UNFOLLOW_ORGANIZER_SUCCESSFUL
                        : HEAP_FOLLOW_ORGANIZER_SUCCESSFUL,
                    eventData: {
                        organizerId: organizerId,
                        pageArea: HEAP_PAGE_AREA,
                    },
                });
            })
            .catch((error) => {
                // reset back to original value bc the request failed
                this.setState({ followedByYou });
            });
    };

    renderButtons = () => {
        const { onContactOrganizerClicked, organizerId } = this.props;
        const { followedByYou, organizer } = this.state;

        if (!organizer) {
            return null;
        }

        const className = cx('eds-l-mar-top-4', styles.organizerActions);

        return (
            <div className={className}>
                <Button
                    data-spec="follow-button"
                    isFollowing={followedByYou}
                    onClick={this.handleClickFollow}
                    size="responsive"
                    style="follow"
                    data-heap-id={
                        followedByYou
                            ? HEAP_UNFOLLOW_ORGANIZER
                            : HEAP_FOLLOW_ORGANIZER
                    }
                    data-organizer-id={organizerId}
                    data-page-area={HEAP_PAGE_AREA}
                >
                    {(followedByYou && gettext('Following')) ||
                        gettext('Follow')}
                </Button>
                {onContactOrganizerClicked && (
                    <Button
                        data-spec="contact-button"
                        onClick={onContactOrganizerClicked}
                        style="link"
                    >
                        {gettext('Contact')}
                    </Button>
                )}
            </div>
        );
    };

    render() {
        const { organizer } = this.state;
        const className = cx(
            'dc-organizer',
            styles.organizer,
            organizer && styles.organizerLoaded,
        );

        return (
            <div className={className}>
                <OrganizerLogo data-spec="organizer-logo" {...organizer} />
                <OrganizerInfo data-spec="organizer-info" {...organizer} />
                <OrganizerSocial data-spec="organizer-social" {...organizer} />
                {this.renderButtons()}
            </div>
        );
    }
}

const styles = {
    organizer: css`
        opacity: 0;
        transition: opacity 0.3s;
    `,
    organizerLoaded: css`
        opacity: 1;
    `,
    organizerActions: css`
        display: flex;
        .eds-btn {
            height: 31px;
            padding: 0 14px 1px;
        }
    `,
};
