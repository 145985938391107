import React from 'react';
import PropTypes from 'prop-types';
import { gettext } from '@eventbrite/i18n';

import { setWindowLocation } from '@eventbrite/http';

import { EmptyState } from '@eventbrite/eds-empty-state';
import { Icon } from '@eventbrite/eds-icon';
import { Lock, User } from '@eventbrite/eds-iconography';

import { getDigitalPagePathWithTld } from '../constants';

const handleAuthRedirect = ({ eventId, isUserAuthenticated }) => {
    let redirectUrl;
    const onlineEventPageUrl = getDigitalPagePathWithTld(eventId);
    const signinUrl = `/signin/?referrer=${encodeURIComponent(
        onlineEventPageUrl,
    )}`;
    const logoutUrl = `/logout/?referrer=${encodeURIComponent(signinUrl)}`;

    if (isUserAuthenticated) {
        redirectUrl = logoutUrl;
    } else {
        redirectUrl = signinUrl;
    }

    setWindowLocation(redirectUrl);
};

const LoginModal = ({ eventId, userEmail, isUserAuthenticated }) => {
    let authMessage, buttonTitle, icon, titleText;

    if (isUserAuthenticated) {
        icon = <Lock />;
        titleText = gettext("You don't have access to this event");
        authMessage = gettext("You're logged in as %(email)s.", {
            email: userEmail,
        });
        buttonTitle = gettext('Sign in to a different account');
    } else {
        icon = <User />;
        titleText = gettext('Sign in to access this event');
        authMessage = gettext("You're not logged in.");
        buttonTitle = gettext('Sign in or create account');
    }

    const title = <span className="eds-text-hs">{titleText}</span>;

    const content = (
        <span
            className={
                'eds-l-mar-vert-4 eds-text-height--open eds-text-color--ui-700'
            }
        >
            <strong>{authMessage}</strong>
            <br />
            {gettext(
                'Sign in with the account you used to register for this event, or purchase a ticket to attend.',
            )}
        </span>
    );

    const loginNote = !isUserAuthenticated ? (
        <div className="eds-empty-state dc-empty-state eds-l-pad-vert-2 eds-l-mar-top-8 eds-align--center">
            <p className="eds-empty-state__description eds-text-color--ui-700">
                {gettext(
                    'If you have not logged in before, follow the steps to create a new account using the email address associated with your ticket.',
                )}
            </p>
        </div>
    ) : null;

    return (
        <div className="eds-text--center eds-l-mar-bot-16">
            <div className="dc-empty-state eds-fx--fade-in eds-align--center">
                <EmptyState
                    data-spec="login-modal"
                    primaryText={buttonTitle}
                    onPrimaryAction={() =>
                        handleAuthRedirect({ eventId, isUserAuthenticated })
                    }
                    graphicType={<Icon type={icon} size="large" />}
                    title={title}
                    description={content}
                />
            </div>
            {loginNote}
        </div>
    );
};

LoginModal.propTypes = {};

export default LoginModal;
