import React, { PureComponent, useState, useEffect } from 'react';
import {
    useQuery,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { gettext } from '@eventbrite/i18n';

import flowRight from 'lodash/flowRight';
import * as analytics from '@eventbrite/site-analytics';

import { withMainControls } from '@eventbrite/eds-structure';
import { withOverlayControls } from '@eventbrite/eds-structure';

import { TYPE_ORBITAL } from '@eventbrite/eds-notification';

import {
    GA_ACTION_CONTACT_ORGANIZER_CLICK,
    GA_ACTION_PAGE_LOCKED,
    GA_DEFAULT_CATEGORY,
    getContactOrganizerPath,
} from '../constants';

import { Header } from '../components/Header';
import Organizer from '../components/Organizer';
import LoginModal from '../components/LoginModal';

import { ATTENDEE_ACCESS_PUBLIC, ATTENDEE_ACCESS_PRIVATE } from '../constants';
import { fetchPrivacySettings } from '../api/structured-content';
import { getExtraData } from '../utils';
import './contentHub.scss';

const DigitalContent = React.lazy(() =>
    import(/* webpackPrefetch: true */ '../components/DigitalContent'),
);

const queryClient = new QueryClient();

export const SkeletonLoader = () => (
    <div className="eds-g-group eds-align--center eds-l-mar-top-8 dc-skeleton-container">
        {Array(3)
            .fill(1)
            .map((_, i) => (
                <div
                    key={i}
                    className="eds-l-pad-all-2 eds-g-cell dc-skeleton-element"
                >
                    <header className={'dc-skeleton'} />
                    <main className={'dc-skeleton'} />
                </div>
            ))}
    </div>
);

export const StructuredContent = ({
    attendeeId,
    event,
    hasPermission,
    isAttendee,
    isUserAuthenticated,
    ticketClasses,
    userEmail,
    userFullName,
    zoomSdkChromeOriginTrialsToken,
}) => {
    const extras = getExtraData({ event, ticketClasses });
    const {
        isLoading,
        data: {
            accessType = ATTENDEE_ACCESS_PRIVATE,
            hideVirtualVenueContent = false,
            modules,
            pagination,
        } = {},
    } = useQuery(
        ['privacySettings', event.id],
        () => fetchPrivacySettings(event.id, extras),
        { retry: false },
    );

    const isPublicEvent = accessType === ATTENDEE_ACCESS_PUBLIC;

    if (isLoading) {
        return <SkeletonLoader />;
    }

    const shouldDisplayLoginModal =
        !isAttendee && !hasPermission && !isPublicEvent;

    // Check that the user can view the content
    if (shouldDisplayLoginModal) {
        analytics.trackAnalyticsEvent({
            category: GA_DEFAULT_CATEGORY,
            action: GA_ACTION_PAGE_LOCKED,
            dimensions: { eventId: event.id },
        });
        return (
            <LoginModal
                userEmail={userEmail}
                isUserAuthenticated={isUserAuthenticated}
                eventId={event.id}
            />
        );
    }

    return (
        <React.Suspense fallback={<SkeletonLoader />}>
            <DigitalContent
                attendeeId={attendeeId}
                event={event}
                ticketClasses={ticketClasses}
                hideVirtualVenueContent={hideVirtualVenueContent}
                modules={modules}
                pagination={pagination}
                userFullName={userFullName}
                userEmail={userEmail}
                zoomSdkChromeOriginTrialsToken={zoomSdkChromeOriginTrialsToken}
            />
        </React.Suspense>
    );
};

export const ContentHubBody = ({
    attendeeId,
    event = {},
    hasPermission,
    isAttendee,
    isUserAuthenticated,
    ticketClasses,
    userEmail,
    userFullName,
    zoomSdkChromeOriginTrialsToken,
}) => {
    const { organizerId } = event;

    const handleContactOrganizer = () => {
        if (event && event.organizer) {
            const eventId = event.id;

            analytics.trackAnalyticsEvent({
                category: GA_DEFAULT_CATEGORY,
                action: GA_ACTION_CONTACT_ORGANIZER_CLICK,
                label: `${event.organizer.name}`,
                dimensions: { eventId },
            });
        }

        const contactOrganizerUrl = `${getContactOrganizerPath(event.id)}`;
        window.location.href = contactOrganizerUrl;
    };

    return (
        <>
            <div className="dc-content">
                <StructuredContent
                    attendeeId={attendeeId}
                    hasPermission={hasPermission}
                    isAttendee={isAttendee}
                    isUserAuthenticated={isUserAuthenticated}
                    ticketClasses={ticketClasses}
                    event={event}
                    userEmail={userEmail}
                    userFullName={userFullName}
                    zoomSdkChromeOriginTrialsToken={
                        zoomSdkChromeOriginTrialsToken
                    }
                />
            </div>
            <div className="dc-sidebar eds-l-mar-bot-14 eds-show-up-ln">
                {organizerId && (
                    <Organizer
                        onContactOrganizerClicked={handleContactOrganizer}
                        organizerId={organizerId}
                    />
                )}
            </div>
        </>
    );
};

export const ContentHub = ({
    addMainNotification,
    attendeeId,
    event,
    hasPermission,
    isAttendee,
    isPreview,
    isUserAuthenticated,
    ticketClasses,
    userEmail,
    userFullName,
    zoomSdkChromeOriginTrialsToken,
}) => {
    useEffect(() => {
        if (isPreview) {
            addMainNotification({
                type: TYPE_ORBITAL,
                children: gettext('Online Event Page Preview'),
                hasCloseButton: true,
                shouldPersist: true,
                isNotificationSticky: true,
            });
        }
    }, [isPreview, addMainNotification]);
    return (
        <div className="dc-hub eds-l-ln-pad-vert-14 eds-l-lg-pad-vert-14 eds-l-lw-pad-vert-14 eds-bg-color--ui-100">
            <div className="dc-body">
                <Header event={event} />
                <div className="dc-body__content eds-l-pad-vert-8">
                    <QueryClientProvider client={queryClient}>
                        <ContentHubBody
                            attendeeId={attendeeId}
                            hasPermission={hasPermission}
                            isAttendee={isAttendee}
                            isUserAuthenticated={isUserAuthenticated}
                            ticketClasses={ticketClasses}
                            event={event}
                            userEmail={userEmail}
                            userFullName={userFullName}
                            zoomSdkChromeOriginTrialsToken={
                                zoomSdkChromeOriginTrialsToken
                            }
                        />
                    </QueryClientProvider>
                </div>
            </div>
        </div>
    );
};

export default flowRight(withOverlayControls, withMainControls)(ContentHub);
