import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { getEventPathWithTld } from '../../constants';
import { Event } from '../../types';
import { EventInfo } from './EventInfo';

export interface Props {
    event?: Event;
}

export const Header: React.FC<Props> = ({ event }) => {
    const [isHeaderOffscreen, setIsHeaderOffscreen] = useState(false);

    if (!event) {
        return null;
    }

    const url = getEventPathWithTld(event.id);

    return (
        <>
            <Waypoint
                onEnter={() => {
                    setIsHeaderOffscreen(false);
                }}
                onLeave={() => {
                    setIsHeaderOffscreen(true);
                }}
            >
                <div>
                    <EventInfo
                        fixed={false}
                        locale={event.locale}
                        logo={event.logo}
                        thumbnails={event.logoThumbnails}
                        name={event.name.text}
                        startTime={event.start.utc}
                        url={url}
                    />
                </div>
            </Waypoint>
            {isHeaderOffscreen && (
                <EventInfo
                    fixed={true}
                    locale={event.locale}
                    logo={event.logo}
                    thumbnails={event.logoThumbnails}
                    name={event.name.text}
                    startTime={event.start.utc}
                    url={url}
                />
            )}
        </>
    );
};
