import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { setLanguage } from '@eventbrite/i18n';
import { SiteStructure } from '@eventbrite/site-structure';
import { deepKeysToCamel } from '@eventbrite/transformation-utils';
import React from 'react';
import { previewReg } from './constants';
import ContentHub from './pages/ContentHub';

// TODO: retrive this info from __SERVER_DATA__
const isPreview = () => {
    if (!HAS_WINDOW) return false;
    const path = window?.location.pathname;
    return previewReg.test(path);
};

// TODO: substitute any's below with proper types
interface Props {
    user: {
        isAuthenticated: boolean;
        email: string;
        fullName: string;
        publicId: string;
    };
    event: any;
    app_version: string;
    app_name: string;
    env: { localeInfo: { locale: string } };
    request: any;
    footerLinks: any;
    isLoading?: boolean;
    zoomSdkChromeOriginTrialsToken?: string;
}

const DigitalContentApp: React.FC<Props> = (props) => {
    const { isAuthenticated = false, email = '', fullName = '' } = props.user;
    const event = deepKeysToCamel(props.event);

    setLanguage(props.env.localeInfo.locale);

    return (
        <SiteStructure
            env={props.env}
            request={props.request}
            user={props.user}
            footerLinks={props.footerLinks}
            isLoading={props.isLoading || false}
            fullScreenMain={true}
        >
            <ContentHub
                {...props}
                event={event}
                isPreview={isPreview()}
                isUserAuthenticated={isAuthenticated}
                userEmail={email}
                userFullName={fullName}
                zoomSdkChromeOriginTrialsToken={
                    props.zoomSdkChromeOriginTrialsToken
                }
            />
        </SiteStructure>
    );
};

export default DigitalContentApp;
