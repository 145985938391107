import React from 'react';
import PropTypes from 'prop-types';
import { GlobalFooter } from '@eventbrite/eds-global-footer';
import { Illustration } from '@eventbrite/eds-illustration';
import {
    LOCALES_ARRAY_PROP_TYPE,
    LOCALE_INFO_SHAPE_PROP_TYPE,
    LINKS_DATA_SHAPE_PROP_TYPE,
    COUNTRIES_ARRAY_PROP_TYPE,
    HELP_LINKS_PROP_TYPE,
} from './constants';
import * as utils from './utils';
import MarketingSection from './MarketingSection';
import { BrandSuperCropIllustration } from '@eventbrite/eds-iconography';
import { HAS_WINDOW } from '../../feature-detection';

export default class SiteFooter extends React.Component {
    static propTypes = {
        serverUrl: PropTypes.string.isRequired,
        locales: LOCALES_ARRAY_PROP_TYPE.isRequired,
        localeInfo: LOCALE_INFO_SHAPE_PROP_TYPE.isRequired,
        popularSearches: LINKS_DATA_SHAPE_PROP_TYPE.isRequired,
        eventsToPlan: LINKS_DATA_SHAPE_PROP_TYPE.isRequired,
        thirdFooterColumn: LINKS_DATA_SHAPE_PROP_TYPE.isRequired,
        thirdFooterColumnName: PropTypes.string.isRequired,
        countries: COUNTRIES_ARRAY_PROP_TYPE.isRequired,
        helpLinks: HELP_LINKS_PROP_TYPE.isRequired,
        onLocaleChange: PropTypes.func,
        isUserAuthenticated: PropTypes.bool,
        showMinimalLinks: PropTypes.bool,
        showMarketingLinks: PropTypes.bool,
        showBrand: PropTypes.bool,
        featureFlags: PropTypes.shape({
            showDoNotSellOrShareDataLinkInFooter: PropTypes.bool,
        }),
    };

    render() {
        const {
            thirdFooterColumn,
            thirdFooterColumnName,
            eventsToPlan,
            serverUrl,
            locales,
            localeInfo,
            helpLinks,
            onLocaleChange,
            popularSearches,
            showBrand,
            showMinimalLinks,
            showMarketingLinks,
            featureFlags,
        } = this.props;

        let generalLinks;
        let brandSection;
        let marketingSection;

        if (showMinimalLinks) {
            generalLinks = utils.getMinimalGeneralLinks(serverUrl);
        } else {
            generalLinks = utils.getGeneralLinks(
                serverUrl,
                localeInfo,
                helpLinks,
            );
        }

        let defaultRegime = 'CPRA';
        if (HAS_WINDOW) {
            defaultRegime = window.airgap?.getRegimes?.().values().next().value;
        }
        const isEvidonFlow = defaultRegime === 'CPRA';

        if (isEvidonFlow) {
            generalLinks.push({
                isEvidonNoticeLink: true,
                content: '',
                url: '',
            });
        } else {
            generalLinks.push(utils.getCookieManagementLink());
        }

        if (featureFlags?.showDoNotSellOrShareDataLinkInFooter) {
            const privacyLink = utils.getPrivacyLink();
            privacyLink && generalLinks.push(privacyLink);
        }

        if (showBrand) {
            brandSection = (
                <Illustration
                    type={<BrandSuperCropIllustration />}
                    width="100%"
                    height="auto"
                />
            );
        }

        if (showMarketingLinks) {
            marketingSection = (
                <MarketingSection
                    serverUrl={serverUrl}
                    popularSearches={popularSearches}
                    eventsToPlan={eventsToPlan}
                    thirdFooterColumn={thirdFooterColumn}
                    thirdFooterColumnName={thirdFooterColumnName}
                    localeInfo={localeInfo}
                />
            );
        }

        const formattedLocales = utils.getLocales(locales);

        return (
            <GlobalFooter
                generalLinks={generalLinks}
                locales={formattedLocales}
                currentLocale={localeInfo.locale}
                onLocaleChange={onLocaleChange}
                brandSection={brandSection}
                flexibleSection={marketingSection}
            />
        );
    }
}
