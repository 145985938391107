import { initializeErrorReporting } from '@eventbrite/error-reporting';
import {
    getLocaleOverride,
    getParentLocale,
    setupAsync,
} from '@eventbrite/i18n';
import {
    initDimensionsFromState,
    trackInitialPageView,
} from '@eventbrite/site-analytics';
import { Event } from '@sentry/types';
import React from 'react';
import { hydrate } from 'react-dom';
import App from './app';
import { ApplicationContext } from './context';
import { initDatadogRUM } from './initDatadogRUM';

const ignoredErrors = [
    /offset is out of bounds/i,
    /Offset should not be negative/i,
    /invalid or out-of-range index/i,
    /memory access out of bounds/i,
    /Out of bounds memory access/i,
    /index out of bounds/i,
    /index is out of bounds/i,
    /null function or function signature mismatch/i,
];

initializeErrorReporting({
    dsn: 'https://bd8cbffd0ccb4c16be48080182e03b1c@o375540.ingest.sentry.io/6308453',
    ignoreError: (event: Event) => {
        const errorMessage = event?.exception?.values?.[0]?.value;

        if (errorMessage) {
            return !!ignoredErrors.find((ignoredError) =>
                ignoredError.test(errorMessage),
            );
        }

        return false;
    },

    tracesSampleRate: 0.35,
});

const props = window.__SERVER_DATA__ || {};
const { featureFlags } = props;

const root = document.getElementById('root');
const parentLocale = getParentLocale(
    getLocaleOverride() || props.env?.localeInfo?.locale,
);

const main = async () => {
    try {
        if (parentLocale !== 'en_US') {
            await setupAsync({
                parentLocale,
                translations:
                    /* webpackChunkName: "digital-content-i18n-translation" */ import(
                        `./i18n/translations/${parentLocale}.json`
                    ),
            });
        }
    } finally {
        hydrate(
            <ApplicationContext.Provider value={{ featureFlags }}>
                <App {...props} />
            </ApplicationContext.Provider>,
            root,
        );
    }
};
main();

initDimensionsFromState(props);
trackInitialPageView(props.gaSettings);

if (isProd(props.env.ebDomain)) {
    initDatadogRUM({
        app: { name: props.app_name, version: props.app_version },
        user: props.user,
    });
}

function isProd(domain: string) {
    return domain.includes('eventbrite');
}
