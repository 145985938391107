import { dangerouslyFetchAllPages, sdkRequest } from '@eventbrite/http';
import { deepKeysToCamel } from '@eventbrite/transformation-utils';
import { formatUrl, UrlParams } from 'url-lib';
import { ATTENDEE_ACCESS_PRIVATE } from '../constants';

// extras contains the following optional parameters for fetching structured content:
// - start_date_utc: the UTC start date in UTC of the event instance (for repeating events)
// - event_date_utc: the UTC end date of the event instance (for repeating events)
// - ticket_classes: the list of ticket classes to check module display restrictions against
export const fetchEventStructuredContentUrl = (
    eventId: string,
    extras: UrlParams = {},
) =>
    formatUrl(`/api/v3/events/${eventId}/structured_content/`, {
        purpose: 'digital_content',
        ...extras,
    });

export const fetchEventStructuredContentApi = async (
    eventId: string,
    extras?: UrlParams,
) =>
    dangerouslyFetchAllPages(
        fetchEventStructuredContentUrl(eventId, extras),
        'modules',
    );

export const fetchPrivacySettings = async (
    eventId: string,
    extras?: UrlParams,
) => {
    const {
        access_type: accessType = ATTENDEE_ACCESS_PRIVATE,
        hide_virtual_venue_content: hideVirtualVenueContent = false,
        modules,
        pagination = {},
    } = await sdkRequest(fetchEventStructuredContentUrl(eventId, extras));

    return deepKeysToCamel({
        accessType,
        hideVirtualVenueContent,
        pagination,
        modules,
    });
};
