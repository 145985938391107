import React from 'react';

export interface FeatureFlags {
    exampleFeatureFlag: boolean;
}

export interface Context {
    featureFlags: FeatureFlags;
}

export const ApplicationContext = React.createContext<Context>({
    featureFlags: { exampleFeatureFlag: false },
});
