import { gettext } from '@eventbrite/i18n';
import React from 'react';

type EventId = string | number;
// This file contains literals and constants used in code.
// For translated static content, see `content,js`

// API
export const API_BASE_PATH = '/api/v3';

// Routes
export const BASE_PATH = '/x';

// Params
export const KEEP_TLD = 'keep_tld=1';

// Google Analytics Stuff
export const GA_ACTION_CONTACT_ORGANIZER_CLICK = 'ContactOrgAttempt';
export const GA_ACTION_FOLLOW_ORGANIZER = 'FollowOrganizer';
export const GA_ACTION_PAGE_LOAD = 'ContentHubPageLoad';
export const GA_ACTION_PAGE_LOCKED = 'ContentHubPageLocked';
export const GA_ACTION_PAGE_NOT_FOUND = 'ContentHubNotFound';
export const GA_ACTION_UNFOLLOW_ORGANIZER = 'UnfollowOrganizer';
export const GA_ACTION_VIEW_FILE_LINK = 'ViewFileLink';
export const GA_ACTION_VIEW_LIVESTREAM_LINK = 'ViewLivestreamLink';
export const GA_ACTION_VIEW_WEBINAR_LINK = 'ViewWebinarLink';
export const GA_DEFAULT_CATEGORY = 'content-hub';
export const GA_ACTION_ZOOM_SDK_LOADED = 'ZoomSDKLoaded';
export const GA_ACTION_ZOOM_APP_LOADED = 'ZoomAPPLoaded';
export const GA_ACTION_ZOOM_APP_LOADED_FROM_SDK = 'ZoomAPPLoadedFromSDK';

export const ZOOM_LEFT_CONTAINER_ID = '#zmmtg-root #wc-container-left';

export const getPath = (eventPath: string) => `${BASE_PATH}/${eventPath}`;
export const getContactOrganizerPath = (eventId: EventId) =>
    `/e/${eventId}/?contact_organizer=1`;
export const getEventPath = (eventId: EventId) => `/e/${eventId}/`;
export const getDigitalPagePath = (eventId: EventId) => `/x/${eventId}/`;
export const getEventPathWithTld = (eventId: EventId) =>
    `/e/${eventId}/?${KEEP_TLD}`;
export const getDigitalPagePathWithTld = (eventId: EventId) =>
    `/x/${eventId}/?${KEEP_TLD}`;

export const MODULE_TYPE_TEXT = 'text';
export const MODULE_TYPE_IMAGE = 'image';
export const MODULE_TYPE_VIDEO = 'video';
export const MODULE_TYPE_WEBINAR = 'webinar';
export const MODULE_TYPE_LIVESTREAM = 'livestream';
export const MODULE_TYPE_FILE = 'file';
export const MODULE_TYPE_ZOOM_MAPPING = 'zoomMapping';

export const DIGITAL_CONTENT_EMBED_PARAM = 'xembed';

export const HTML_SANITIZER_CONFIG = Object.freeze({
    allowedTags: Object.freeze([
        'h1',
        'h2',
        'h3',
        'p',
        'aside',
        'blockquote',
        'strong',
        'em',
        'ol',
        'ul',
        'li',
        'a',
        'b',
    ]),
    allowedAttributes: Object.freeze({
        a: Object.freeze(['href']),
    }),
    allowedSchemes: Object.freeze(['http', 'https']),
    nonTextTags: Object.freeze([]),
});

export const INTERVAL_TO_REFRESH_MODULES = 60 * 1000;

export const INTERVAL_TO_CHECK_ZOOM_ACCESS = 1000;
export const ZOOM_STARTING_SOON_MINUTES = 120;
export const ZOOM_STARTING_NOW_MINUTES = 5;
export const ZOOM_DEFAULT_STARTING_SOON_MINUTES = '30';

export const ZOOM_LOGO =
    'https://eventbrite-s3.s3.amazonaws.com/marketing/landingpages/sponsorship/digital-content/zoom-icon.png';

export const ZOOM_IMG = (
    <img
        data-spec="zoom-image"
        src={ZOOM_LOGO}
        width="24"
        className={'eds-l-pad-right-2'}
        alt={gettext('Zoom Icon').toString()}
    />
);

export const EVENT_TYPE = {
    ZOOM_EVENT_TYPE_MEETING: 'meeting',
    ZOOM_EVENT_TYPE_WEBINAR: 'webinar',
};

export const ATTENDEE_ACCESS_PRIVATE = 'private';
export const ATTENDEE_ACCESS_PUBLIC = 'public';

export const ZOOM_API_KEY = 'Kw8Z6OXTSLiIy4WYbNT23w';
export const ZOOM_LATEST_VERSION = '1.8.5';

export const previewReg = new RegExp(/preview/i);

export enum BrowserTypes {
    chrome = 'Chrome',
    edge = 'Edge',
    firefox = 'Firefox',
    internetExplorer = 'Internet Explorer',
    opera = 'Opera',
    safari = 'Safari',
    samsungInternet = 'Samsung Internet',
    unknown = 'unknown',
}

export const COLORS = {
    uiElevated: '0px 2px 10px rgba(19, 0, 45, 0.1)',
    uiCellBg: 'rgba(248, 247, 250, 0.5)',
    uiBlueHover: '#3d64ff',
    ui100: '#f8f7fa',
    ui200: '#eeedf2',
    ui600: '#6f7287',
    ui800: '#39364f',
};
