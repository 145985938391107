import * as React from 'react';

const NewWindowChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            d="M18 18v2H4V6h7v2H6v10h10v-5h2zm1-11.586l-7.293 7.293-1.414-1.414L17.586 5H14V3h7v7h-2z"
            fillRule="evenodd"
        />
    </svg>
);

NewWindowChunkySvg.displayName = 'NewWindowChunkySvg';
export default NewWindowChunkySvg;
