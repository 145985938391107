import { Icon } from '@eventbrite/eds-icon';
import { NewWindowChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import momentTz from 'moment-timezone';
import React from 'react';
import { EventThumbnail } from '../../types';
import { EventLogo } from './EventLogo';

export interface Props {
    fixed?: boolean;
    locale: string;
    logo?: { url: string };
    name: string;
    startTime: string;
    thumbnails?: EventThumbnail[];
    url: string;
}

export const EventInfo: React.FC<Props> = ({
    name,
    startTime,
    url,
    logo,
    thumbnails,
    locale,
    fixed = false,
}) => {
    const formattedStartDatetime = () => {
        momentTz.locale(locale);
        const localTimezone = momentTz.tz.guess(true);
        const startMoment = momentTz.utc(startTime);

        return startMoment.tz(localTimezone).format('LLLL (z)');
    };

    const headerContentClasses = classNames([
        'dc-header',
        { 'dc-header--fixed': fixed },
        'eds-l-pad-bottom-14',
        'eds-bg-color--background',
    ]);

    const hasImage =
        (!logo || !logo.url) && (!thumbnails || isEmpty(thumbnails));

    const eventCardClasses = classNames([
        'dc-event-card',
        { 'dc-event-card--no-logo': !hasImage },
        { 'eds-l-lg-pad-vert-8': !fixed },
        { 'eds-l-lw-pad-vert-8': !fixed },
        { 'eds-l-ln-pad-vert-8': !fixed },
        'eds-l-pad-vert-4',
    ]);

    const eventCardDateClasses = classNames(['eds-text-bm', 'eds-l-mar-bot-2']);

    const eventCardTitleClasses = classNames([
        'dc-event-card__title',
        'eds-text-hs',
        'eds-l-mar-bot-2',
    ]);

    const eventDetailsLinkClasses = classNames([
        { 'eds-show-down-mw': fixed },
        'eds-l-mar-bot-5',
        'eds-text-weight--heavy',
        'eds-text-color--control',
    ]);

    return (
        <div className={headerContentClasses}>
            <EventLogo logo={logo} thumbnails={thumbnails} />
            <div className={eventCardClasses}>
                <div className="dc-event-card__content">
                    <div className="dc-event-card__details">
                        <p
                            className={eventCardDateClasses}
                            data-testid="event-date"
                        >
                            {formattedStartDatetime()}
                        </p>
                        <h1
                            className={eventCardTitleClasses}
                            data-testid="event-title"
                        >
                            {name}
                        </h1>
                        <a
                            className={eventDetailsLinkClasses}
                            data-testid="event-listing-url"
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {gettext('View event details')}
                            <span className="new-window-icon-wrapper eds-l-mar-left-2">
                                <Icon
                                    type={<NewWindowChunky />}
                                    color="ui-blue"
                                    size="xsmall"
                                />
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
