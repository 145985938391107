import { datadogRum } from '@datadog/browser-rum-slim';
import {
    getCurrentEnv,
    getWindowObject,
    HAS_WINDOW,
} from '@eventbrite/feature-detection';
import {
    BOT_REGEX_PATTERN,
    WEB_TRIAL_APP_TOKEN,
    WEB_TRIAL_CLIENT_TOKEN,
} from '../constants';
import { RUMOptions } from '../types';

const addEBSessionId = (options: RUMOptions) => {
    if (!HAS_WINDOW) {
        return;
    }
    const sessionId =
        options.ebSessionId || window.__SERVER_DATA__?.request?.session_id;

    //Necessary to wrap the call in onReady as addRumGlobalContext only operates
    //onces RUM instance is already created.
    //https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=cdnasync#add-global-context
    if (sessionId) {
        datadogRum.onReady(() =>
            datadogRum.addRumGlobalContext('session.eb_session_id', sessionId),
        );
    }
};

/**
 *   __ebuiRUMInit is declared globally in an inline script,
 *   the responsibility of the function is to indicate that RUM is ready
 *   TODO: add to eb-ui docs about tracking custom paint actions
 */
const notifyRUMReady = () => {
    if (!HAS_WINDOW) {
        return;
    }

    datadogRum.onReady(() => {
        window.__ebuiRUMInit && window.__ebuiRUMInit();
    });
};

export function getSampleRate(options: RUMOptions): number {
    let sampleRate = options?.sampleRate || 0;

    if (HAS_WINDOW && getWindowObject('navigator') && !options.enableBots) {
        const botRegex = new RegExp(BOT_REGEX_PATTERN, 'i');
        sampleRate = botRegex.test(navigator.userAgent) ? 0 : sampleRate;
    }

    return sampleRate;
}

export const initializeRealUserMonitoring = (options: RUMOptions) => {
    if (!HAS_WINDOW) {
        return;
    }

    const currentEnv = options.env ? options.env : getCurrentEnv() || undefined;

    if (
        options.enabledEnvironments &&
        currentEnv &&
        !options.enabledEnvironments.includes(currentEnv)
    ) {
        return;
    }

    const clientToken = options.clientToken || WEB_TRIAL_CLIENT_TOKEN;
    const applicationId = options.applicationId || WEB_TRIAL_APP_TOKEN;
    const version = options.version || '';
    const service = options.service ? `rum-web--${options.service}` : 'rum-web';

    const sampleRate = getSampleRate(options);

    datadogRum.init({
        ...options,
        applicationId,
        clientToken,
        version,
        service,
        sampleRate,
        site: 'datadoghq.com',
        trackInteractions: true,
        env: currentEnv,
    });

    addEBSessionId(options);
    notifyRUMReady();
};

/**
 * Thin wrapper to pass a custom action to the RUM tracking instance
 * defined on the window.
 *
 * @generic RUMEvents
 *      The shape of the events that your application supports
 *      eg. type MyEvents = {search: {query: string;}}
 *
 * @param name Top level key defined in the passed in RUMEvents
 *
 * @param data Payload to send for a given event
 */
export const addCustomRUMEvent = <
    Events,
    EventName extends keyof Events = keyof Events,
>(
    name: EventName,
    data: Events[EventName],
) => {
    if (
        typeof window !== 'undefined' &&
        window.DD_RUM &&
        window.DD_RUM.addAction
    ) {
        window.DD_RUM.addAction(name, data);
    }
};

export const setUser = datadogRum.setUser;

/**
 * Thin wrapper to pass a custom error to the RUM tracking instance
 * defined on the window.
 *
 * @param error Error to send to DD
 *
 * @param context Payload to send for a given error
 */
export const addCustomRUMError = (error: unknown, context?: object) => {
    if (
        typeof window !== 'undefined' &&
        window.DD_RUM &&
        window.DD_RUM.addError
    ) {
        window.DD_RUM.addError(error, context);
    }
};
